import {
  Box,
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { TextField, Button } from "@mui/material";
// import ReCaptchaV2 from "react-google-recaptcha";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { verfiyUser } from "../../api/auth";
import { UseAuthContext } from "../../context/auth-context";
import Captcha from "../Captcha/Captcha";
import Loader from "../Loader/Loader";

export default function Login({
  toggle,
  setToggle,
  loginScreen,
  setLoginScreen,
  setSignUpScreen,
  setForgotScreen,
  onFinish,
  loader,
  setLoader,
  singleCall,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [reRender, setRerender] = useState(true);
  const [loginForm, setLoginForm] = useState({
    emailid: "",
    password: "",
    checkboxCookie: false,
  });
  const captchaRef = useRef();
  const isClicked = useRef(false);

  const { token } = UseAuthContext();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setLoginForm({
      ...loginForm,
      [event.target.name]: sanitizedValue,
    });
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      email.trim().length > 0 &&
      emailRegex.test(email) &&
      !email.includes(",") &&
      !email.includes(" ")
    );
  }

  const createLogin = (isValid) => {
    if (!isValidEmail(loginForm.emailid)) {
      toast.error("Enter Correct Email Address ");
      return;
    }
    // if (loginForm.emailid.trim() === "") {
    //   toast.error("Email is required ");
    //   return;
    // }

    // if (
    //   !loginForm.emailid.split("").includes("@") ||
    //   !loginForm.emailid.split("").includes(".") ||
    //   loginForm.emailid.includes(" ")
    // ) {
    //   toast.error("Enter Correct Email Address ");
    //   return;
    // }

    if (loginForm.password.trim() === "") {
      toast.error("Password is required");
      return;
    }

    if (!isValid) {
      toast.error("Invalid Captcha");
      return;
    }

    if (
      loginForm.checkboxCookie === "" ||
      loginForm.checkboxCookie === undefined ||
      loginForm.checkboxCookie === false
    ) {
      toast.error("Please accept Terms of Usage ");
      return;
    }

    if (!isClicked.current) {
      setLoader(true);
      setShowPassword(false);
      isClicked.current = true;
      onFinish(loginForm);
      setLoginForm({
        ...loginForm,
        checkboxCookie: false,
      });
      isClicked.current = false;
      setRerender(false);
      setTimeout(() => {
        setRerender(true);
      }, 0);
    }
  };

  useEffect(() => {
    if (token && singleCall) {
      setLoginForm({
        emailid: "",
        password: "",
        checkboxCookie: false,
      });
      toast.success("Login Successfully");
      setRerender(false);
      setTimeout(() => {
        setRerender(true);
      }, 0);
      setLoader(false);
      setShowPassword(false);
      setToggle(false);
    } else if (token) {
      setLoginForm({
        emailid: "",
        password: "",
        checkboxCookie: false,
      });
      setRerender(false);
      setTimeout(() => {
        setRerender(true);
      }, 0);
      setLoader(false);
      setShowPassword(false);
      setToggle(false);
    }
    // eslint-disable-next-line
  }, [token, singleCall]);

  const handleCaptchaValidation = (isValid) => {
    // if (isValid) {
    //   createLogin();
    // } else {
    //   toast.error("Invalid Captcha");
    // }
    createLogin(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    captchaRef.current.validateCaptcha();
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Box
          className="loginContainer DF JCC AIC"
          display={toggle && loginScreen ? "" : "none"}
        >
          <Box className="boxshadow4sides bordr10px loginForm P2 PB1">
            {reRender ? (
              <Box>
                <Box className="DF JCSB">
                  <Typography variant={"h1"} className="contactusformheading">
                    {"Login"}
                  </Typography>
                  <Box
                    className="cursorPointer"
                    onClick={() => {
                      setLoginForm({
                        emailid: "",
                        password: "",
                        checkboxCookie: false,
                      });
                      setRerender(false);
                      setTimeout(() => {
                        setRerender(true);
                      }, 0);
                      setShowPassword(false);
                      setToggle(false);
                    }}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
                <Typography variant={"h6"} className="loginTextSubHeading">
                  {"Please enter your Email and Password"}
                </Typography>

                <Box
                  sx={{ mt: 3.25 }}
                  className="DF JCSB FFC getintouchinputfield"
                >
                  <TextField
                    name="emailid"
                    label="Email"
                    className="accordionbggrey LoginInputs"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={loginForm.emailid}
                  />

                  <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    className="accordionbggrey LoginInputs"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    value={loginForm.password}
                  />
                </Box>
                {/* <Box
              className="cursorPointer MT-10 MB20"
              onClick={() => {
                setForgotScreen("forgot");
                setLoginScreen(false);
              }}
            >
              First time here? Please create your password.
            </Box> */}
                {/* <Box className="reCaptcha">
              <ReCaptchaV2
                sitekey={"6Ld5c_UoAAAAAHqS4gazOrVdYHcxJ6t5K-9MfKfY"}
                className="MB20"
                ref={captchaRef}
              />
            </Box> */}

                <Captcha
                  ref={captchaRef}
                  onValidate={handleCaptchaValidation}
                  lable={"Enter Captcha"}
                  // helpText={"Invalid Captcha"}
                />

                <Box component={"div"} className="DF AIC MB20 verySmallMobMB10">
                  <Checkbox
                    style={{ padding: "2px 1rem 0px 0rem" }}
                    className=""
                    name="checkboxCookie"
                    defaultChecked={false}
                    value={loginForm.checkboxCookie}
                    onChange={handleChange}
                  ></Checkbox>
                  <Typography className="Contactustermsofusage linehtN colorBlack">
                    {"I accept Indxx's terms of usage, acknowledge the "}
                    <Link
                      to={"/data-privacy"}
                      target="_blank"
                      className="colorBlack hoverUnderline"
                    >
                      {"Data Privacy"}
                    </Link>
                    {" Policy, and authorize to contact."}
                  </Typography>
                </Box>

                <Button
                  className="sendmsgBtnLogin mobMT20 mobMAuto linehtN"
                  onClick={handleSubmit}
                >
                  {"Submit"}
                </Button>
                <Box className="DF JCSB PT10">
                  <Box
                    className="cursorPointer hoverUnderline"
                    onClick={() => {
                      setLoginForm({
                        emailid: "",
                        password: "",
                        checkboxCookie: false,
                      });
                      setRerender(false);
                      setTimeout(() => {
                        setRerender(true);
                      }, 0);
                      setSignUpScreen(true);
                      setShowPassword(false);
                      setLoginScreen(false);
                    }}
                  >
                    New user? Click here to Sign up.
                  </Box>
                  <Box
                    className="cursorPointer hoverUnderline"
                    onClick={() => {
                      setLoginForm({
                        emailid: "",
                        password: "",
                        checkboxCookie: false,
                      });
                      setRerender(false);
                      setTimeout(() => {
                        setRerender(true);
                      }, 0);
                      setForgotScreen("forgot");
                      setShowPassword(false);
                      setLoginScreen(false);
                    }}
                  >
                    Forgot password?
                  </Box>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
