import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { getMethodApi } from "../../Utils/Methods";
import { GET_ANNOUNCEMENTS_BY_LIMIT, GET_CONFIG } from "../../Apis/EndPoints";
import { useState } from "react";
import TabsPanelByYear from "../../Components/TabsPanelByYear/TabsPanelByYear";
import Loader from "../../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UseAuthContext } from "../../context/auth-context";
import Auth from "../../Components/Authentication/Auth";

export default function Announcement() {
  const [announcementsdata, setannouncementsdata] = useState([]);
  const [loader, setLoader] = useState(true);
  const [setTimeOutLoader, setSetTimeOutLoader] = useState(true);
  // eslint-disable-next-line
  const [isAnnouncmentEnable, setIsAnnouncmentEnable] = useState(null);
  const location = useLocation();
  const [seoData, setSeoData] = useState("");
  const [loader2, setLoader2] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  // eslint-disable-next-line
  const [logoutBtn, setLogoutBtn] = useState(false);
  const { token } = UseAuthContext();


  useEffect(() => {
    setTimeout(() => {
      let queryParam = location?.pathname?.split("announcements/")[1];
      if (queryParam === "index-notifications") {
        document.getElementById(`sections1`).scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "end",
        });
      }
      if (queryParam === "index-documents") {
        document.getElementById(`sections2`).scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "end",
        });
      }
    }, 1000);
  }, [location?.pathname]);

  useEffect(() => {
    getMethodApi(GET_CONFIG).then((response) => {
      if (response.status === 200) {
        // setIsAnnouncmentEnable(Number(response.data["Announcment_page_enable"][0].value))
        setSeoData(response.data);
        setLoader2(false);
      }
    });

    getMethodApi(GET_ANNOUNCEMENTS_BY_LIMIT).then((response) => {
      if (response.status === 200) {
        setannouncementsdata(response.data);
        setLoader(false);
      }
    });

    setTimeout(() => {
      setSetTimeOutLoader(false);
    }, 2000);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (token) {
      setIsAnnouncmentEnable(1);
    } else {
      setIsAnnouncmentEnable(0);
    }
    // eslint-disable-next-line
  }, [token]);

  function getyrs(data) {
    for (let item in data) {
      let inneritem = Object.keys(data[item]);
      return inneritem;
    }
  }

  function gettabdata(data) {
    for (let item in data) {
      let inneritem = Object.values(data[item]);
      return inneritem;
    }
  }

  return (
    <>
      {/* Seo */}
      <Helmet>
        <title>
          {seoData?.Announ_Title ? seoData.Announ_Title[0].value : "Title"}
        </title>
        <meta
          name="description"
          content={
            seoData?.Announ_Description
              ? seoData.Announ_Description[0].value
              : "description"
          }
        />
        <meta
          name="keywords"
          content={
            seoData?.Announ_Keywords ? seoData.Announ_Keywords[0].value : "keys"
          }
        />
      </Helmet>
      <Box className="announcementimg smallMobPB0 DF JCC AIC PL1 PR1">
        <Typography variant={"h1"} className="topHeadingPage W60 TAC mobW100">
          {"Announcements"}
        </Typography>
      </Box>

      <Box
        component="div"
        className="container MT100 mobMT80 smallMobMT40 verySmallMobMT20"
      >
        {/* {announcementsdata.length !== 0 && isAnnouncmentEnable */}
        {announcementsdata.length !== 0
          ? announcementsdata?.map((data, index) => {
            // if (Object.keys(data)[0] === "Index Notifications" && isAnnouncmentEnable === 0) {
            //   return ""
            // }
            return (
              <Box
                className="DF newsInsightContainer mobFFC boxshadow4sides announcementouterbox MB50 smallMobMB30 IStretchF DF FFC JCSB"
                key={index}
                id={`sections${index + 1}`}
              >
                <TabsPanelByYear
                  topheadeing={Object.keys(data)}
                  tabHeadings={getyrs(data).reverse()}
                  tabData={gettabdata(data).reverse()}
                  setToggle={setToggle}
                  setLoginScreen={setLoginScreen}
                />
              </Box>
            )
          })
          : ""}
      </Box>

      {/* Authentication */}
      <Auth
        toggle={toggle}
        setToggle={setToggle}
        loginScreen={loginScreen}
        setLoginScreen={setLoginScreen}
        setLogoutBtn={setLogoutBtn}
        singleCall={false}
      />
      {/* loader */}
      {setTimeOutLoader || loader || loader2 ? <Loader /> : ""}
    </>
  );
}
