import { useState, useEffect } from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import Forgot from "./Forgot";
import OTP from "./otp";
// import { getUserPoolData } from "../../api/auth";
import { UseAuthContext } from "../../context/auth-context";
import CognitoService from "../../Utils/cognitoHelper";
// import { verfiyUser } from "../../api/auth";
import { toast } from "react-toastify";
import ConfirmID from "./ConfirmID";
// import { CLIENTID, USERPOOLID } from "../../api/endpoint/auth";

export default function Auth({
  toggle,
  setToggle,
  loginScreen,
  setLoginScreen,
  setLogoutBtn,
  singleCall
}) {
  //   const [toggle, setToggle] = useState(false);
  //   const [loginScreen, setLoginScreen] = useState(false);
  const [signUpScreen, setSignUpScreen] = useState(false);
  const [forgotScreen, setForgotScreen] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);
  const [otpConfirmScreen, setOtpConfirmScreen] = useState(false);
  //   const [logoutBtn, setLogoutBtn] = useState(false);
  const [index, setIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  // const CLIENTID = "1v771pm1bs844lardbodm8mshh";
  // const USERPOOLID = "ap-south-1_3QPTftAex";
  const CLIENTID = "3i60lpr3chc286ffs2hdsmb5ii";
  const USERPOOLID = "ap-south-1_1nQMu9MDO";

  const { token, login, authError, resetError } = UseAuthContext();

  useEffect(() => {
    // _getUserPoolData();
    localStorage.setItem("clientId", CLIENTID);
    localStorage.setItem("userPoolId", USERPOOLID);
  }, [CLIENTID]);

  useEffect(() => {
    let indexValue = 0;
    const timer = setInterval(() => setIndex(indexValue), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [index]);

  useEffect(() => {
    if (authError && authError.error) {
      toast.error(authError.error);
      resetError();
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [authError]);

  useEffect(() => {
    if (token) {
      setLoader(false);
      // setIsSpinning(false);
      // const params = {
      //   email: localStorage.getItem("email"),
      // };
      // verfiyUser(params).then((response) => {
      // if (response.data.status === "success") {
      setLogoutBtn(true);
      // });
      // }
    } else {
      setLogoutBtn(false);
    }
  }, [token, setLogoutBtn]);

  // const _getUserPoolData = async () => {
  //   try {
  //     const res = await getUserPoolData();
  //     const clientId = res.data[2]["Value"];
  //     const userPoolId = res.data[1]["Value"];
  //     localStorage.setItem("clientId", clientId);
  //     localStorage.setItem("userPoolId", userPoolId);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onFinish = (formData) => {
    const { emailid, password } = formData;
    // setIsSpinning(true);
    login(emailid, password);
  };

  // const onFinishSignUp = async (formData) => {
  //   const otp = String(formData.otp);
  //   const newPassword = formData.newPassword;
  //   try {
  //     // eslint-disable-next-line
  //     const res = await CognitoService.registerUser({
  //       otp,
  //       newPassword,
  //     });
  //     setToggle(false);
  //     setOtpScreen(false);
  //     toast.success("Password changed successfully");
  //   } catch (error) {
  //     toast.error("Invalid OTP, Please try again");
  //   }
  // };

  const onSetConfirmForgotPasswordFinish = async (formData) => {
    const otp = String(formData.otp);
    const newPassword = formData.newPassword;
    try {
      // eslint-disable-next-line
      const res = await CognitoService.confirmNewForgotPassword({
        otp,
        newPassword,
      });
      setToggle(false);
      setOtpScreen(false);
      setLoader(false);
      toast.success("Password changed successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Invalid OTP, Please try again");
    }
    // setShowPasswordDetailModal(true);
    // reset form
    // form.resetFields();
  };

  const onFinishForgotPassword = async (formData) => {
    // setIsSpinning(true);

    const username = formData.emailid;
    localStorage.setItem("forgotEmail", username);
    try {
      // eslint-disable-next-line
      const res = await CognitoService.forgotPasswordReset({
        username,
      });
    } catch (error) {}
  };

  const onFinishConfirmNewEmailID = async (formData) => {
    const username = formData.emailid;
    const otp = String(formData.otp);
    localStorage.setItem("newEmail", username);
    try {
      // eslint-disable-next-line
      const res = await CognitoService.confirmNewEmailId({
        otp,
      });
    } catch (error) {}
  };

  return (
    <>
      <Login
        toggle={toggle}
        setToggle={setToggle}
        loginScreen={loginScreen}
        setLoginScreen={setLoginScreen}
        setSignUpScreen={setSignUpScreen}
        setForgotScreen={setForgotScreen}
        onFinish={onFinish}
        loader={loader}
        setLoader={setLoader}
        singleCall={singleCall}
      />
      <SignUp
        toggle={toggle}
        setToggle={setToggle}
        signUpScreen={signUpScreen}
        setSignUpScreen={setSignUpScreen}
        setLoginScreen={setLoginScreen}
        setOtpConfirmScreen={setOtpConfirmScreen}
      />
      <Forgot
        toggle={toggle}
        setToggle={setToggle}
        forgotScreen={forgotScreen}
        setLoginScreen={setLoginScreen}
        setSignUpScreen={setSignUpScreen}
        setForgotScreen={setForgotScreen}
        setOtpScreen={setOtpScreen}
        onFinishForgotPassword={onFinishForgotPassword}
      />
      <OTP
        toggle={toggle}
        setToggle={setToggle}
        loginScreen={loginScreen}
        setLoginScreen={setLoginScreen}
        setSignUpScreen={setSignUpScreen}
        setForgotScreen={setForgotScreen}
        otpScreen={otpScreen}
        setOtpScreen={setOtpScreen}
        onSetConfirmForgotPasswordFinish={onSetConfirmForgotPasswordFinish}
        loader={loader}
        setLoader={setLoader}
      />
      <ConfirmID
        toggle={toggle}
        setToggle={setToggle}
        loginScreen={loginScreen}
        setLoginScreen={setLoginScreen}
        setSignUpScreen={setSignUpScreen}
        setForgotScreen={setForgotScreen}
        otpScreen={otpScreen}
        setOtpScreen={setOtpScreen}
        otpConfirmScreen={otpConfirmScreen}
        setOtpConfirmScreen={setOtpConfirmScreen}
        onFinishForgotPassword={onFinishForgotPassword}
        onFinishConfirmNewEmailID={onFinishConfirmNewEmailID}
      />
    </>
  );
}
