import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect } from "react";
import {
  Link as RouterLink,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Logo from "./../../Assets/Svgs/indxxLogo.svg";
import { getMethodApi } from "../../Utils/Methods";
import {
  GET_TABS,
  GET_SEARCH_SUGGESTION,
  // GET_CONFIG,
} from "../../Apis/EndPoints";
import useStyles from "../../Assets/Styles/Common/Common";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "./../../Assets/Svgs/search.svg";
import CloseIcon from "./../../Assets/Svgs/close.svg";
import { UseAuthContext } from "../../context/auth-context";
// import { logoutUser } from "../../api/auth";
import { toast } from "react-toastify";
import Auth from "../Authentication/Auth";

export default function Header() {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [tabsData, setTabsData] = useState([]);
  const [scroller, initScroller] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeDropdownNested, setActiveDropdownNested] = useState(null);
  const [active, setActive] = useState(10);
  const [search, setSearch] = useState(true);
  const [searchSuggestion, setSearchSuggestion] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchComponent, setSearchComponent] = useState(true);
  const [advertisingHeader, setAdvertisingHeader] = useState(false);
  // eslint-disable-next-line
  const [isAnnouncmentEnable, setIsAnnouncmentEnable] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [logoutBtn, setLogoutBtn] = useState(false);

  const {
    header,
    logo,
    toolbarOuter,
    boxShadow,
    toolbarInner,
    toolbarInnerHebrew,
    drawerContainer,
    menuBtnsHdr,
    drawerToolbar,
    menuIconMobContainer,
    toolbarOuterWithoutSearch,
  } = useStyles();
  const { mobileView, drawerOpen } = state;

  const { token, logout } = UseAuthContext();

  const handleLogout = () => {
    // const params = {
    //   email: localStorage.getItem("email"),
    // };
    // logoutUser(params).then((res) => {});
    logout();
    toast.success("Logout successful. Thanks for visiting!");
  };

  useEffect(() => {
    if (token) {
      setIsAnnouncmentEnable(1);
    } else {
      setIsAnnouncmentEnable(0);
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    // getMethodApi(GET_CONFIG).then((response) => {
    //   if (response.status === 200) {
    //     setIsAnnouncmentEnable(
    //       Number(response.data["Announcment_page_enable"][0].value)
    //     );
    //   }
    // });
    getMethodApi(GET_TABS).then((response) => {
      if (response.status === 200) {
        setTabsData(response.data.H);
      }
    });
    getMethodApi(GET_SEARCH_SUGGESTION).then((response) => {
      if (response.status === 200) {
        setSearchSuggestion(response.data);
      }
    });
  }, []);

  useEffect(() => {
    let selectedtab = window.location.href.split("/")[3];
    let graphpage = window.location.href.split("/")[5];

    if (
      selectedtab === "indices" &&
      graphpage !== undefined
      // (selectedtab === "indices" && graphpage !== undefined) ||
      // selectedtab === "contact-us"
    ) {
      document.body.style.zoom = 1;
    } else {
      document.body.style.zoom = 0.8;
    }

    if (selectedtab === "") {
      setActive(0);
    } else if (selectedtab === "indxx-indices") {
      setActive(1);
    } else if (selectedtab === "custom-indices") {
      setActive(1);
    } else if (selectedtab === "client-indices") {
      setActive(1);
    } else if (selectedtab === "equity-basket-calculation") {
      setActive(1);
    } else if (selectedtab === "index-services") {
      setActive(1);
    } else if (selectedtab === "index-services") {
      setActive(1);
    } else if (selectedtab === "itics") {
      setActive(1);
    } else if (selectedtab === "cadmin") {
      setActive(1);
    } else if (selectedtab === "about-us") {
      setActive(2);
    } else if (selectedtab === "aboutus") {
      setActive(2);
    } else if (selectedtab === "indices") {
      setActive(3);
    } else if (selectedtab === "announcements") {
      setActive(4);
    } else if (selectedtab === "news-&-research") {
      setActive(5);
    } else if (selectedtab === "blog") {
      setActive(5);
    } else if (selectedtab === "contact-us") {
      setActive(6);
    } else if (selectedtab === "contactus") {
      setActive(6);
    } else if (selectedtab === "careers") {
      setActive(6);
    } else {
      setActive(10);
    }
    let queryParam = location?.pathname;
    if (queryParam.includes("/search")) {
      setSearchComponent(false);
    } else {
      setSearchComponent(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (
      location?.pathname?.includes("%ED%95%9C%EA%B5%AD%EC%96%B4") ||
      location?.pathname?.includes("%D7%A2%D6%B4%D7%91%D7%A8%D6%B4%D7%99%D7%AA")
    ) {
      setAdvertisingHeader(true);
    } else {
      setAdvertisingHeader(false);
    }
    if (location?.pathname?.endsWith("/*")) {
      navigate(location?.pathname?.slice(0, -2));
    }
    if (
      location?.pathname?.includes(
        "/blog/economic-growth-of-india-a-new-star-on-asias-horizon"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/economic-growth-of-india-a-new-star-on-asia-horizon"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/from-revolution-to-reinvention-three-industries-transformed-by-robotics-and-artificial-intelligence"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/from-revolution-to-reinvention-three-industries-transformed-by-robotics-and-artificial-intelligence"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/rise-of-the-electric-vehicle-battery-market-and-its-roadblocks"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/rise_of_the_electric_vehicle_battery_market_and_its_roadblocks_research"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/five-sectors-primed-for-flourishing-growth-through-ai-investments"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/five-sectors-primed-for-flourishing-growth-through-ai-investments"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/from-moon-landings-to-mars-missions-the-growth-of-the-space-industry-and-its-potential-for-investors"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/from-moon-landings-to-mars-missions-the-growth-of-the-space-industry-and-its-potential-for-investors"
      );
    }
    if (location?.pathname?.includes("/blog/green_infrastructure")) {
      navigate(
        "/news-&-research/news-and-research-insider/investing-in-green-infrastructure-7-themes-to-watch-out-for"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/metaverse-the-rise-of-a-new-digital-era"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/metaverse-the-rise-of-a-new-digital-era"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/navigating-the-risk-of-inaccurate-corporate-actions-processing"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/Navigating-the-Risk-of-Inaccurate-Corporate-Actions-Processing"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/Navigating-the-Risk-of-Inaccurate-Corporate-Actions-Processing"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/Navigating-the-Risk-of-Inaccurate-Corporate-Actions-Processing"
      );
    }
    if (
      location?.pathname?.includes(
        "blog/rise-of-the-electric-vehicle-battery-market-and-its-roadblocks"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/rise_of_the_electric_vehicle_battery_market_and_its_roadblocks_research"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/role-of-ai-in-the-metaverse-tech-giants-growing-strategic-investments"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/role-of-ai-in-the-metaverse-tech-giants-growing-strategic-investments"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/space-economy-takes-off-whats-in-store-for-2023-and-beyond"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/space-economy-takes-off-whats-in-store-for-2023-and-beyond"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/the-aftermath-of-the-federal-reserves-interest-rate-hikes-asian-markets-appear-bleak"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/the-aftermath-of-the-federal-reserve’s-interest-rate-hikes"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/the-new-age-of-energy-how-indias-renewable-energy-industry-is-transforming-the-sector"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/the-new-age-of-energy-how-indias-renewable-energy-industry-is-transforming-the-sector"
      );
    }
    if (
      location?.pathname?.includes("/blog/united_states_aerospace_and_defense")
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/us-aerospace-&-defense-industry-secret-weapon"
      );
    }
    if (
      location?.pathname?.includes(
        "/blog/ev-battery-market-powering-the-ev-revolution"
      )
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/ev-battery-market-powering-the-ev-revolution"
      );
    }
    if (location?.pathname?.split("/").includes("news")) {
      navigate("/news-&-research/in-the-news");
    }
    if (location?.pathname?.includes("/cookiepolicy")) {
      navigate("/cookie-policy");
    }
    if (location?.pathname?.includes("/dataprivacy")) {
      navigate("/data-privacy");
    }
    if (
      location?.pathname?.includes(
        "/indxx-artificial-intelligence--big-data-index-ntr"
      )
    ) {
      navigate(
        "/indices/thematic-indices/indxx_artificial_intelligence_&_big_data_index"
      );
    }
    if (
      location?.pathname?.includes(
        "/indices/thematic/indxx-us-junior-cybersecurity-index-ntr"
      )
    ) {
      navigate("/indices/thematic-indices/indxx_us_junior_cybersecurity_index");
    }
    if (
      location?.pathname?.includes("/indxx-aging-population-thematic-index-tr")
    ) {
      navigate(
        "/indices/thematic-indices/indxx_aging_population_thematic_index"
      );
    }
    if (
      location?.pathname?.includes("/indxx-global-fintech-thematic-index-tr")
    ) {
      navigate("/indices/thematic-indices/indxx_global_fintech_thematic_index");
    }
    if (
      location?.pathname?.includes("/indxx-global-FinTech-thematic-index-tr")
    ) {
      navigate("/indices/thematic-indices/indxx_global_fintech_thematic_index");
    }
    if (
      location?.pathname?.includes(
        "/indxx-global-health--wellness-thematic-index-tr"
      )
    ) {
      navigate(
        "/indices/thematic-indices/indxx_global_health_&_wellness_thematic_index"
      );
    }
    if (
      location?.pathname?.includes(
        "/indxx-global-internet-of-things-thematic-index-tr"
      ) ||
      location?.pathname?.includes(
        "/indxx-global-internet-of-things-thematic-index-tr"
      )
    ) {
      navigate("/indices/thematic-indices/indxx_global_medical_devices_index");
    }
    if (
      location?.pathname?.includes(
        "/indxx-global-robotics--artificial-intelligence-thematic-index-tr"
      )
    ) {
      navigate(
        "/indices/thematic-indices/indxx_global_robotics_&_artificial_intelligence_thematic_index"
      );
    }
    if (location?.pathname?.includes("/federal_reserve_interest_rate_hike")) {
      navigate(
        "/news-&-research/news-and-research-insider/the-aftermath-of-the-federal-reserve’s-interest-rate-hikes"
      );
    }
    if (location?.pathname?.includes("/indxx-500-index-ntr")) {
      navigate("/indices/benchmark-indices/indxx_500_index");
    }
    if (location?.pathname?.includes("/indxx-blockchain-index-tr")) {
      navigate("/indices/thematic-indices/indxx_blockchain_index");
    }
    if (
      location?.pathname?.includes("/indxx-climate-change-solutions-index-ntr")
    ) {
      navigate(
        "/indices/thematic-indices/indxx_climate_change_solutions_index"
      );
    }
    if (location?.pathname?.includes("/indxx-cybersecurity-index-ntr")) {
      navigate("/indices/thematic-indices/indxx_cybersecurity_index");
    }
    if (
      location?.pathname?.includes("/indxx-disruptive-technologies-index-pr")
    ) {
      navigate("/indices/thematic-indices/indxx_disruptive_technologies_index");
    }
    if (
      location?.pathname?.includes("/indxx-disruptive-technologies-index-tr")
    ) {
      navigate("/indices/thematic-indices/indxx_disruptive_technologies_index");
    }
    if (location?.pathname?.includes("/indxx-global-agriculture-index-tr")) {
      navigate("/indices/benchmark-indices/indxx_global_agriculture_index");
    }
    if (location?.pathname?.includes("/indxx-global-cleantech-index-tr")) {
      navigate("/indices/benchmark-indices/indxx_global_agriculture_index");
    }
    if (location?.pathname?.includes("/indxx-global-cloud-computing-index")) {
      navigate("/indices/thematic-indices/indxx_global_cloud_computing_index");
    }
    if (
      location?.pathname?.includes("/indxx-global-cloud-computing-v2-index-tr")
    ) {
      navigate(
        "/indices/thematic-indices/indxx_global_cloud_computing_v2_index"
      );
    }
    if (location?.pathname?.includes("/indxx-global-e-commerce-index-ntr")) {
      navigate("/indices/thematic-indices/indxx_global_e_-commerce_index");
    }
    if (
      location?.pathname?.includes(
        "/indxx-global-generics--new-pharma-index-tr"
      )
    ) {
      navigate(
        "/indices/thematic-indices/indxx_global_generics_&_new_pharma_index"
      );
    }
    if (
      location?.pathname?.includes("/indxx-global-medical-devices-index-ntr")
    ) {
      navigate("/indices/thematic-indices/indxx_global_medical_devices_index");
    }
    if (location?.pathname?.includes("/indxx-india-infrastructure-index-pr")) {
      navigate("/indices/benchmark-indices/indxx_india_infrastructure_index");
    }
    if (location?.pathname?.includes("/indxx-japan-e-commerce-index-ntr")) {
      navigate("/indices/thematic-indices/indxx_japan_e-commerce_index");
    }
    if (location?.pathname?.includes("/indxx-private-credit-index")) {
      navigate("/indices/income-indices/indxx_private_credit_index");
    }
    if (location?.pathname?.includes("/indxx-renewable-energy-index-ntr")) {
      navigate("/indices/thematic-indices/indxx_renewable_energy_index");
    }
    if (
      location?.pathname?.includes(
        "/indxx-superdividend-u.s.-low-volatility-index-tr"
      )
    ) {
      navigate(
        "/indices/income-indices/Indxx-SuperDividend-U.S-%20Low-Volatility-Index"
      );
    }
    if (
      location?.pathname?.includes(
        "/indxx-u.s.-infrastructure-development-index-tr"
      )
    ) {
      navigate(
        "/indices/benchmark-indices/indxx_u.s._infrastructure_development_index"
      );
    }
    if (location?.pathname?.includes("/indxx-us-e-commerce-index-ntr")) {
      navigate("/indices/thematic-indices/indxx_us_e-commerce_index");
    }
    if (
      location?.pathname?.includes(
        "/indxx-us-egaming--virtual-reality-index-pr"
      )
    ) {
      navigate(
        "/indices/thematic-indices/indxx_us_egaming_&_virtual_reality_index"
      );
    }
    if (location?.pathname?.includes("/indxx-us-tech-top-10-index-tr")) {
      navigate("/indices/thematic-indices/indxx_us_tech_top_10_index");
    }
    if (location?.pathname?.includes("/indxx-usa-cloud-computing-index-ntr")) {
      navigate("/indices/thematic-indices/indxx_usa_cloud_computing_index");
    }
    if (
      location?.pathname?.includes("/metaverse_the_rise_of_a_new_digital_era")
    ) {
      navigate(
        "/news-&-research/news-and-research-insider/metaverse-the-rise-of-a-new-digital-era"
      );
    }
    if (
      location?.pathname?.includes(
        "/north-shore-global-uranium-mining-index-tr"
      )
    ) {
      navigate(
        "/indices/other-indices/north_shore_global_uranium_mining_index"
      );
    }
    if (location?.pathname?.includes("/united_states_aerospace_and_defense")) {
      navigate(
        "/news-&-research/news-and-research-insider/us-aerospace-defense-armed-to-the-teeth-or-dodging-a-bullet"
      );
    }
    // if (location?.pathname?.includes("")) {
    //   navigate("");
    // }
  }, [location?.pathname, navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        initScroller(false);
      } else {
        initScroller(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // document.body.style.zoom = "80%";
  }, []);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth <= 1100
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handleDrawerOpen = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  };
  const handleDrawerClose = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
    setActiveDropdown(null);
    setActiveDropdownNested(null);
  };

  const onChange = (event) => {
    setSearchInput(event.target.value);
  };
  const onSearch = (searchTerm) => {
    setSearchInput(searchTerm.trim());
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchInput) {
        setSearch(true);
        onSearch(searchInput);
        navigate(`/search/${searchInput}`);
      }
    }
  };

  const displayDesktop = () => {
    return (
      <Toolbar
        className={` ${
          searchComponent ? toolbarOuter : toolbarOuterWithoutSearch
        } ${scroller ? "" : boxShadow}`}
      >
        <Box
          className={
            location?.pathname?.includes(
              "%D7%A2%D6%B4%D7%91%D7%A8%D6%B4%D7%99%D7%AA"
            )
              ? toolbarInnerHebrew
              : toolbarInner
          }
        >
          {femmecubatorLogo}
          {search ? (
            <Box
              className={`${menuBtnsHdr} opacityTransition ${
                advertisingHeader ? "visibilityNone" : ""
              }`}
            >
              {getMenuButtonsAll()}
              {logoutBtn ? (
                <Box
                  onClick={() => {
                    handleLogout();
                    setLogoutBtn(false);
                  }}
                  component={"div"}
                  className={"loginBtn cursorPointer DF JCC AIC"}
                >
                  <Typography>Logout</Typography>
                </Box>
              ) : (
                <Box
                  onClick={() => {
                    setToggle(true);
                    setLoginScreen(true);
                  }}
                  component={"div"}
                  className={"loginBtn cursorPointer DF JCC AIC"}
                >
                  <Typography>Login</Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              component={"div"}
              className={`searchBarHeaderBox bGColorGrey DF AIC JCSB`}
            >
              <input
                placeholder="Search"
                type="text"
                onChange={onChange}
                autoFocus
                value={searchInput.trimStart()}
                onKeyDown={handleKeyDown}
                className="opacityTransition W80 searchBarHeader ML24"
                // ref={inputRef}
              />

              <Box
                onClick={() => {
                  if (searchInput) {
                    setSearch(true);
                    onSearch(searchInput);
                    navigate(`/search/${searchInput}`);
                  }
                }}
                className="cursorPointer searcchIconInHeader DF JCC AIC MR13"
              >
                <img
                  src={SearchIcon}
                  alt="searchAndCloseLogo"
                  className="opacityTransition ML10"
                />
              </Box>
              {searchInput ? (
                <Box
                  component={"div"}
                  className="posAbs searchBarHeaderDropDown PL24 PR20"
                >
                  {searchSuggestion
                    ?.filter((item) => {
                      const searchTerm = searchInput.toLowerCase();
                      const data = item.toLowerCase();

                      return (
                        searchTerm &&
                        data.includes(searchTerm) &&
                        data !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((item, index) => {
                      return (
                        <Box key={index}>
                          <Box
                            component={"div"}
                            className="cursorPointer MT5 MB5 colorDarkGrey noWrap overflowHidden textOverFlowEllipsis"
                            onClick={() => {
                              onSearch(item);
                              setSearch(true);
                              setSearchInput(item);
                              navigate(`/search/${item}`);
                            }}
                          >
                            {item}
                          </Box>
                          {searchSuggestion
                            ?.filter((item) => {
                              const searchTerm = searchInput.toLowerCase();
                              const data = item.toLowerCase();

                              return (
                                searchTerm &&
                                data.startsWith(searchTerm) &&
                                data !== searchTerm
                              );
                            })
                            .slice(0, 10).length -
                            1 !==
                          index ? (
                            <Box
                              sx={{
                                // borderBottom: "1px solid #616564",
                                p: "1px",
                              }}
                            ></Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                ""
              )}
            </Box>
          )}
          {searchComponent ? (
            <Box
              component={"div"}
              className={`cursorPointer opacityTransition DF JCC AIC ${
                advertisingHeader ? "visibilityNone" : ""
              }`}
              onClick={() => {
                setSearch(!search);
                setSearchInput("");
              }}
            >
              {search ? (
                <img
                  src={SearchIcon}
                  alt="searchAndCloseLogo"
                  className="opacityTransition"
                />
              ) : (
                <img
                  src={CloseIcon}
                  alt="searchAndCloseLogo"
                  className="opacityTransition closeIconInLogo"
                />
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar className={`${drawerToolbar} ${scroller ? "" : boxShadow}`}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <Box className={drawerContainer}>
            {logoutBtn ? (
              <Box className={"MT20 MB10"}>
                <Box
                  className="links"
                  onClick={() => {
                    handleLogout();
                    setLogoutBtn(false);
                    handleDrawerClose();
                  }}
                >
                  <MenuItem>Logout</MenuItem>
                </Box>
              </Box>
            ) : (
              <Box className={"MT20 MB10"}>
                <Box
                  className="links"
                  onClick={() => {
                    setToggle(true);
                    setLoginScreen(true);
                    handleDrawerClose();
                  }}
                >
                  <MenuItem>Login</MenuItem>
                </Box>
              </Box>
            )}
            {getDrawerChoices()}
          </Box>
        </Drawer>

        <IconButton
          {...{
            edge: "start",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
            className: `${menuIconMobContainer} ${
              advertisingHeader ? "visibilityNone" : ""
            }`,
          }}
        >
          <MenuIcon />
        </IconButton>

        {search ? (
          <Box>{femmecubatorLogo}</Box>
        ) : (
          <Box
            component={"div"}
            className="searchBarHeaderBox bGColorGrey DF AIC JCSB"
          >
            <input
              placeholder="Search"
              type="text"
              onChange={onChange}
              autoFocus
              value={searchInput.trimStart()}
              onKeyDown={handleKeyDown}
              className="opacityTransition W80 searchBarHeader ML24"
              // ref={inputRef}
            />

            <Box
              onClick={() => {
                if (searchInput) {
                  setSearch(true);
                  onSearch(searchInput);
                  navigate(`/search/${searchInput}`);
                }
              }}
              className="cursorPointer searcchIconInHeader DF JCC AIC MR13"
            >
              <img
                src={SearchIcon}
                alt="searchAndCloseLogo"
                className="opacityTransition ML10"
              />
            </Box>
            {searchInput ? (
              <Box
                component={"div"}
                className="posAbs searchBarHeaderDropDown PL24 PR20"
              >
                {searchSuggestion
                  ?.filter((item) => {
                    const searchTerm = searchInput.toLowerCase();
                    const data = item.toLowerCase();

                    return (
                      searchTerm &&
                      data.includes(searchTerm) &&
                      data !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item, index) => {
                    return (
                      <Box key={index}>
                        <Box
                          component={"div"}
                          className="cursorPointer MT5 MB5 colorDarkGrey noWrap overflowHidden textOverFlowEllipsis"
                          onClick={() => {
                            onSearch(item);
                            setSearch(true);
                            setSearchInput(item);
                            navigate(`/search/${item}`);
                          }}
                        >
                          {item}
                        </Box>
                        {searchSuggestion
                          ?.filter((item) => {
                            const searchTerm = searchInput.toLowerCase();
                            const data = item.toLowerCase();

                            return (
                              searchTerm &&
                              data.startsWith(searchTerm) &&
                              data !== searchTerm
                            );
                          })
                          .slice(0, 10).length -
                          1 !==
                        index ? (
                          <Box
                            sx={{
                              // borderBottom: "1px solid #616564",
                              p: "1px",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    );
                  })}
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}
        {searchComponent ? (
          <Box
            component={"div"}
            className={`cursorPointer opacityTransition DF JCC AIC ${
              advertisingHeader ? "visibilityNone" : ""
            }`}
            onClick={() => {
              setSearch(!search);
              setSearchInput("");
            }}
          >
            {search ? (
              <img
                src={SearchIcon}
                alt="searchAndCloseLogo"
                className="opacityTransition"
              />
            ) : (
              <img
                src={CloseIcon}
                alt="searchAndCloseLogo"
                className="opacityTransition closeIconInLogo"
              />
            )}
          </Box>
        ) : (
          ""
        )}
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return tabsData?.map(({ Link, Name, sub_tabs }, ind) => {
      if (
        sub_tabs &&
        (Name === "What We Do" || sub_tabs[0].Name === "Overview")
      ) {
        return (
          <Box key={ind} className="MT25 MB25">
            <Box
              onClick={() => {
                setActiveDropdownNested(null);
                if (activeDropdown === ind) {
                  setActiveDropdown(null);
                } else {
                  setActiveDropdown(ind);
                }
              }}
              component={"div"}
              className="links DF PL1 PR1"
            >
              <Typography>{Name}</Typography>
              <Box
                sx={{ marginLeft: ".4rem" }}
                component={"span"}
                className={`DF JCC AIC`}
              >
                <KeyboardArrowDownIcon />
              </Box>
            </Box>

            {activeDropdown === ind ? (
              <Box component={"div"} className="MT20">
                {sub_tabs?.map((ele, indx) => {
                  return (
                    <>
                      <Box
                        key={indx}
                        sx={{ margin: ".5rem 1rem" }}
                        className="links menuButtonNestedMob"
                      >
                        <Box
                          onClick={() => {
                            if (activeDropdownNested === ind) {
                              setActiveDropdownNested(null);
                            } else {
                              setActiveDropdownNested(ind);
                            }
                          }}
                          className="DF"
                        >
                          <RouterLink
                            onClick={() => {
                              if (!ele.sub_tabs) {
                                setActiveDropdown(null);
                                handleDrawerClose();
                              }
                            }}
                            to={`/${
                              sub_tabs[0].Name === "Overview"
                                ? `${Name.toLowerCase().split(" ").join("-")}/${
                                    ele.Name === "Overview"
                                      ? ""
                                      : ele.slug
                                      ? ele.slug
                                      : ele.Name.toLowerCase()
                                          .split(" ")
                                          .join("-")
                                  }`
                                : `${ele.Name.toLowerCase()
                                    .split(" ")
                                    .join("-")}`
                            }`}
                            className="links nestedLinks DF"
                          >
                            <li>{ele.Name}</li>
                          </RouterLink>
                          {ele.sub_tabs ? (
                            <Box
                              sx={{ marginLeft: ".4rem" }}
                              component={"span"}
                              className={`DF JCC AIC`}
                            >
                              <KeyboardArrowDownIcon />
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                        {indx === sub_tabs.length - 1 ? (
                          ""
                        ) : (
                          <Box
                            sx={{
                              opacity: "0.1",
                              borderBottom: "1px solid #000000",
                              paddingBottom: ".5rem",
                            }}
                          ></Box>
                        )}
                      </Box>
                      {activeDropdownNested && ele.sub_tabs?.length > 0
                        ? ele.sub_tabs?.map((ele2, indxs) => {
                            return (
                              <Box
                                key={indxs}
                                sx={{ margin: ".5rem 1rem" }}
                                className="links menuButtonNestedMob ML1"
                              >
                                <RouterLink
                                  onClick={() => {
                                    handleDrawerClose();
                                  }}
                                  to={`/${
                                    Name === "Indices"
                                      ? `indices/${ele2.slug}`
                                      : ele2.Name.toLowerCase()
                                  }`}
                                  className="links nestedLinks PL20 DF"
                                >
                                  <li>{ele2.Name}</li>
                                </RouterLink>
                                {indx === ele.sub_tabs.length - 1 ? (
                                  ""
                                ) : (
                                  <Box
                                    className="ML1"
                                    sx={{
                                      opacity: "0.1",
                                      borderBottom: "1px solid #000000",
                                      paddingBottom: ".5rem",
                                    }}
                                  ></Box>
                                )}
                              </Box>
                            );
                          })
                        : ""}
                    </>
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>
        );
      } else if (sub_tabs) {
        return (
          <Box key={ind} className="MT25 MB25">
            <Box
              onClick={() => {
                setActiveDropdownNested(null);
                if (activeDropdown === ind) {
                  setActiveDropdown(null);
                } else {
                  setActiveDropdown(ind);
                }
              }}
              component={"div"}
              className="links DF PL1"
            >
              <RouterLink
                onClick={() => {
                  // if (!ele.sub_tabs) {
                  setActiveDropdown(null);
                  handleDrawerClose();
                  // }
                }}
                to={`/${Name.toLowerCase().split(" ").join("-")}`}
                className="links nestedLinks DF"
              >
                <Typography>{Name}</Typography>
              </RouterLink>
              <Box
                sx={{ marginLeft: ".4rem" }}
                component={"span"}
                className={`DF JCC AIC`}
              >
                <KeyboardArrowDownIcon />
              </Box>
            </Box>

            {activeDropdown === ind ? (
              <Box component={"div"} className="MT20">
                {sub_tabs?.map((ele, indx) => {
                  // if (
                  //   isAnnouncmentEnable === 0 &&
                  //   ele.Name === "Index Notifications"
                  // ) {
                  //   return "";
                  // }
                  return (
                    <>
                      <Box
                        key={indx}
                        sx={{ margin: ".5rem 1rem" }}
                        className="links menuButtonNestedMob"
                      >
                        <Box
                          onClick={() => {
                            if (activeDropdownNested === ind) {
                              setActiveDropdownNested(null);
                            } else {
                              setActiveDropdownNested(ind);
                            }
                          }}
                          className="DF"
                        >
                          <RouterLink
                            onClick={() => {
                              if (!ele.sub_tabs) {
                                setActiveDropdown(null);
                                handleDrawerClose();
                              }
                            }}
                            to={`/${Name.toLowerCase()
                              .split(" ")
                              .join("-")}/${ele.Name.toLowerCase()
                              .split(" ")
                              .join("-")}`}
                            replace
                            className="links nestedLinks DF"
                          >
                            <li>{ele.Name}</li>
                          </RouterLink>
                          {ele.sub_tabs ? (
                            <Box
                              sx={{ marginLeft: ".4rem" }}
                              component={"span"}
                              className={`DF JCC AIC`}
                            >
                              <KeyboardArrowDownIcon />
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                        {indx === sub_tabs.length - 1 ? (
                          ""
                        ) : (
                          <Box
                            sx={{
                              opacity: "0.1",
                              borderBottom: "1px solid #000000",
                              paddingBottom: ".5rem",
                            }}
                          ></Box>
                        )}
                      </Box>
                      {/* {activeDropdownNested && ele.sub_tabs?.length > 0
                        ? ele.sub_tabs?.map((ele2, indxs) => {
                            return (
                              <Box
                                key={indx}
                                sx={{ margin: ".5rem 1rem" }}
                                className="links menuButtonNestedMob ML1"
                              >
                                <Link
                                  onClick={() => {
                                    handleDrawerClose();
                                  }}
                                  href={`/${
                                    Name === "Indices"
                                      ? `indices/${ele2.slug}`
                                      : ele2.Name.toLowerCase()
                                  }`}
                                  replace
                                  className="links nestedLinks PL20 DF"
                                >
                                  <li>{ele2.Name}</li>
                                </Link>
                                {indx === ele.sub_tabs.length - 1 ? (
                                  ""
                                ) : (
                                  <Box
                                    className="ML1"
                                    sx={{
                                      opacity: "0.1",
                                      borderBottom: "1px solid #000000",
                                      paddingBottom: ".5rem",
                                    }}
                                  ></Box>
                                )}
                              </Box>
                            );
                          })
                        : ""} */}
                    </>
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>
        );
      } else {
        return (
          <Box className={"MT10 MB-10"} key={ind}>
            <NavLink
              {...{
                component: RouterLink,
                to:
                  Name === "Home"
                    ? "/"
                    : Name.toLowerCase().split(" ").join("-"),
                key: ind,
                className: "links MT25 MB25",
              }}
              onClick={() => handleDrawerClose()}
            >
              <MenuItem>{Name}</MenuItem>
            </NavLink>
          </Box>
        );
      }
    });
  };

  const femmecubatorLogo = (
    <Box
      onClick={() => {
        setActive(0);
        navigate("/");
        setSearch(true);
        setSearchInput("");
      }}
    >
      <img src={Logo} alt="" className={logo} />
    </Box>
  );

  const getMenuButtonsAll = () => {
    return tabsData?.map(({ Link, Name, sub_tabs }, ind) => {
      if (
        sub_tabs &&
        (Name === "What We Do" || sub_tabs[0].Name === "Overview")
      ) {
        return (
          <Box key={ind}>
            <Box
              onMouseEnter={() => setActiveDropdown(null)}
              onClick={() => {
                if (activeDropdown === ind) {
                  setActiveDropdown(null);
                } else {
                  setActiveDropdown(ind);
                }
              }}
              component={"div"}
              className={
                active === ind
                  ? "menuButtonActive cursorPointer DF JCC AIC"
                  : "menuButton links DF JCC AIC dropDownHeaderHeading"
              }
            >
              <Typography>{Name}</Typography>
              <Box
                component={"span"}
                className={`DF JCC AIC ${
                  active === ind ? "dropdownArrowHeader MR-10" : ""
                }`}
              >
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            {activeDropdown === ind ? (
              <Box
                onMouseLeave={() => {
                  setActiveDropdownNested(null);
                  setActiveDropdown(null);
                }}
                component={"div"}
                className="dropDownHeader"
              >
                <Box className="dropDownHeaderTopStrip"></Box>
                {sub_tabs?.map((ele, indx) => {
                  return (
                    <>
                      <Box
                        key={indx}
                        sx={{ padding: ".5rem 1rem" }}
                        className={`links menuButtonNested ${
                          ele.sub_tabs ? "" : ""
                        }`}
                      >
                        <RouterLink
                          onClick={() => {
                            setActive(ind);
                            setActiveDropdown(null);
                          }}
                          onMouseEnter={() => {
                            if (ele.sub_tabs) {
                              if (activeDropdownNested === ind) {
                                setActiveDropdownNested(null);
                              } else {
                                setActiveDropdownNested(ind);
                              }
                            }
                          }}
                          to={
                            sub_tabs[0].Name === "Overview"
                              ? `${Name.toLowerCase().split(" ").join("-")}/${
                                  ele.Name === "Overview"
                                    ? ""
                                    : ele.slug
                                    ? ele.slug
                                    : ele.Name
                                }`
                              : `${ele.Name.toLowerCase().split(" ").join("-")}`
                          }
                          className={`links menuButtonNested ${
                            ele.sub_tabs ? "PR10 DF AIC JCSB" : ""
                          }`}
                          sx={{
                            fontSize: "0.938rem",
                            letterSpacing: "-0.01em",
                          }}
                        >
                          {ele.Name}
                          {ele.sub_tabs ? <ChevronRightIcon /> : ""}
                        </RouterLink>
                        {indx === sub_tabs.length - 1 ? (
                          ""
                        ) : (
                          <Box
                            sx={{
                              opacity: "0.1",
                              borderBottom: "1px solid #000000",
                              paddingBottom: ".5rem",
                            }}
                          ></Box>
                        )}
                      </Box>
                      {activeDropdownNested && ele?.sub_tabs?.length > 0 ? (
                        <Box
                          component={"div"}
                          className="dropDownHeaderNested"
                          sx={{ top: `${40 * (sub_tabs?.length - 1)}px` }}
                        >
                          <Box className="dropDownHeaderTopStrip"></Box>
                          {ele.sub_tabs?.map((ele2, indxs) => {
                            return (
                              <Box
                                key={indxs}
                                sx={{ padding: ".5rem 1rem" }}
                                className="links menuButtonNested"
                              >
                                <RouterLink
                                  onClick={() => {
                                    setActive(ind);
                                    setActiveDropdown(null);
                                    setActiveDropdownNested(null);
                                  }}
                                  to={
                                    Name === "Indices"
                                      ? `indices/${ele2.slug}`
                                      : ele2.Name.toLowerCase()
                                          .split(" ")
                                          .join("")
                                  }
                                  className="links menuButtonNested"
                                  sx={{
                                    fontSize: "0.938rem",
                                    letterSpacing: "-0.01em",
                                  }}
                                >
                                  {ele2.Name}
                                </RouterLink>
                                {indxs === ele.sub_tabs?.length - 1 ? (
                                  ""
                                ) : (
                                  <Box
                                    sx={{
                                      opacity: "0.1",
                                      borderBottom: "1px solid #000000",
                                      paddingBottom: ".5rem",
                                    }}
                                  ></Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>
        );
      } else if (sub_tabs) {
        return (
          <Box key={ind}>
            <Box
              className={`DF ${
                active === ind ? "menuButtonActive" : "menuButton"
              }`}
            >
              <NavLink
                onMouseEnter={() => setActiveDropdown(null)}
                onClick={() => setActive(ind)}
                {...{
                  key: ind,
                  to: Name.toLowerCase().split(" ").join("-"),
                  component: RouterLink,
                  className:
                    active === ind
                      ? "menuButtonActive2 cursorPointer DF JCC AIC"
                      : "menuButton2 links DF JCC AIC dropDownHeaderHeading",
                }}
              >
                <Typography>{Name}</Typography>
              </NavLink>
              <Box
                sx={{ marginLeft: ".4rem" }}
                component={"span"}
                className={`DF JCC AIC cursorPointer ${
                  active === ind ? "dropdownArrowHeader MR-10" : ""
                }`}
              >
                <KeyboardArrowDownIcon
                  onClick={() => {
                    if (activeDropdown === ind) {
                      setActiveDropdown(null);
                    } else {
                      setActiveDropdown(ind);
                    }
                  }}
                />
              </Box>
            </Box>

            {activeDropdown === ind ? (
              <Box
                onMouseLeave={() => setActiveDropdown(null)}
                component={"div"}
                className="dropDownHeader"
              >
                <Box className="dropDownHeaderTopStrip"></Box>
                {sub_tabs?.map((ele, indx) => {
                  // if (
                  //   isAnnouncmentEnable === 0 &&
                  //   ele.Name === "Index Notifications"
                  // ) {
                  //   return "";
                  // }
                  return (
                    <Box
                      key={indx}
                      sx={{ padding: ".5rem 1rem" }}
                      className="links menuButtonNested"
                    >
                      <RouterLink
                        onClick={() => {
                          setActive(ind);
                          setActiveDropdown(null);
                        }}
                        to={`${Name.toLowerCase()
                          .split(" ")
                          .join("-")}/${ele.Name.toLowerCase()
                          .split(" ")
                          .join("-")}`}
                        className="links menuButtonNested"
                        sx={{ fontSize: "0.938rem", letterSpacing: "-0.01em" }}
                      >
                        {ele.Name}
                      </RouterLink>
                      {indx === sub_tabs.length - 1 ? (
                        ""
                      ) : (
                        <Box
                          sx={{
                            opacity: "0.1",
                            borderBottom: "1px solid #000000",
                            paddingBottom: ".5rem",
                          }}
                        ></Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>
        );
      } else {
        return (
          <NavLink
            onClick={() => {
              setActiveDropdown(null);
              setActive(ind);
            }}
            {...{
              key: ind,
              to:
                Name === "Home" ? "/" : Name.toLowerCase().split(" ").join("-"),
              component: RouterLink,
              className:
                active === ind ? "menuButtonActive DF JCC AIC" : "menuButton",
            }}
          >
            <Typography>{Name}</Typography>
          </NavLink>
        );
      }
    });
  };

  return (
    <>
      <AppBar
        className={`${header} userSelectNone`}
        sx={{ backgroundColor: "white" }}
        elevation={0}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      <Auth
        toggle={toggle}
        setToggle={setToggle}
        loginScreen={loginScreen}
        setLoginScreen={setLoginScreen}
        setLogoutBtn={setLogoutBtn}
        singleCall={true}
      />
    </>
  );
}
